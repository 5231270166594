<template>
  <v-card
    v-if="
      USER.userInfo.departments.length > 0 ||
      DEPARTMENT.cityDepartment.length > 0
    "
    id="schedule"
    :dark="$dark.get()"
  >
    <v-card-title class="header">
      {{ $route.name }}
    </v-card-title>
    <v-card-text>
      <loader v-if="loading"></loader>
      <schedule-filter
        :roles="roles"
        :filter="filter"
        :input="input"
        :departments="USER.userInfo.departments"
        :mobile-mode="scheduleTableMode"
        @print="print()"
        @excel="modal = true"
      ></schedule-filter>
      <schedule-table
        v-if="!loading && !scheduleTableMode"
        :date="input.date"
        :pickers="pickerByDep"
        :couriers="courierByDep"
        :role="filter.role"
        :input="input"
        @rerender="setAll"
      ></schedule-table>
      <mobile-schedule-table
        v-if="!loading && scheduleTableMode"
        :date="input.date"
        :pickers="pickerByDep"
        :couriers="courierByDep"
        :role="filter.role"
        :input="input"
        @rerender="setAll"
      />

      <v-dialog
        v-model="modal"
        activator="parent"
        width="50%"
        @click:outside="modal = false"
      >
        <v-card :dark="$dark.get()">
          <v-card-title class="justify-center"
            >Скачивание табеля сотрудников</v-card-title
          >
          <v-form ref="form">
            <v-card-text>
              <v-select
                v-model="exelInput.departmentId"
                label="Филиал"
                item-text="name"
                item-value="id"
                hide-details
                :items="USER.userInfo.departments"
              ></v-select>
              <v-menu
                ref="menu"
                v-model="menu"
                transition="scale-transition"
                offset-y
                :dark="$dark.get()"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="dateFormat"
                    label="Месяц"
                    readonly
                    v-bind="attrs"
                    hide-details
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="exelInput.date" type="month" no-title>
                </v-date-picker>
              </v-menu>
            </v-card-text>
            <v-card-actions class="flex justify-end">
              <v-btn @click="modal = false">Закрыть</v-btn>
              <v-btn color="primary" @click="downloadExel()">Скачать</v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>
<script>
import moment from "moment";
import ScheduleTable from "../components/Schedule/ScheduleTable";
import MobileScheduleTable from "../components/Schedule/mobileScheduleTable.vue";
import { mapGetters, mapActions } from "vuex";
import Loader from "../components/Loader.vue";
import ScheduleFilter from "../components/Schedule/ScheduleFilter";
import Stomp from "webstomp-client";
import api from "@/api";

export default {
  components: {
    ScheduleFilter,
    ScheduleTable,
    MobileScheduleTable,
    Loader,
  },
  data() {
    return {
      input: {
        date: moment().format("YYYY-MM"),
        mobileDate: moment().format("YYYY-MM-DD"),
        departmentId: 5,
      },
      couriers: [],
      pickers: [],
      loading: true,
      roles: [
        {
          name: "Все",
          id: 0,
        },
        {
          name: "Курьеры",
          id: 1,
        },
        {
          name: "Сборщики",
          id: 2,
        },
      ],
      filter: {
        role: 0,
      },
      modal: false,
      exelInput: {
        date: moment().format("YYYY-MM"),
        departmentId: 5,
      },
      menu: false,
    };
  },
  computed: {
    ...mapGetters({
      SCHEDULE: "Schedule/STATE",
      EMPLOYEE: "Employee/STATE",
      DEPARTMENT: "Department/STATE",
      USER: "User/STATE",
    }),
    dateFormat() {
      return moment(this.exelInput.date).format("За MMMM YYYY");
    },
    pickerByDep() {
      return this.pickers.filter((x) => {
        if (
          x.employee.departments
            .map((y) => {
              return y.id;
            })
            .indexOf(this.input.departmentId) >= 0
        )
          return x;
      });
    },
    courierByDep() {
      return this.couriers.filter((x) => {
        if (
          x.employee.departments
            .map((y) => {
              return y.id;
            })
            .indexOf(this.input.departmentId) >= 0
        )
          return x;
      });
    },
    scheduleTableMode() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        case "lg":
          return false;
        case "xl":
          return false;
        default:
          return false;
      }
    },
  },
  watch: {
    input: {
      async handler() {
        this.loading = true;
        await this.setAll();
        this.loading = false;
      },
      deep: true,
    },
  },
  async mounted() {
    await this.checkPower();
    await this.getDepartment(this.$root.city);
    if (this.USER.userInfo.departments.length) {
      this.input.departmentId = this.USER.userInfo.departments[0].id;
    } else {
      this.input.departmentId = this.DEPARTMENT.cityDepartment[0].id;
    }
    await this.employeeCity(this.$root.city);
    await this.setAll();
    await this.connect();
    this.loading = false;
  },
  methods: {
    ...mapActions({
      shiftList: "Schedule/SHIFT_LIST",
      employeeCity: "Employee/GET_CITY_EMPLOYEE",
      getDepartment: "Department/GET_DEPARTMENT",
      report: "File/REPORT_POST",
    }),
    print() {
      let divToPrint = document.getElementById("printSection");
      let newWin = window.open("");
      newWin.document.write(divToPrint.outerHTML);
      newWin.print();
      newWin.close();
    },
    async downloadExel() {
      this.loading = true;
      const response = await api.Schedule.getExelShedule(this.exelInput);
      if (response.type !== "error") {
        let baseURL = process.env.VUE_APP_FILE_URL;
        let url = baseURL + "/uploads/" + response + ".xlsx";
        const link = document.createElement("a");
        link.href = url;
        link.download = response;
        link.click();
        URL.revokeObjectURL(link.href);
      }
      this.modal = false;
      this.loading = false;
    },
    checkPower() {
      let state = this.$power.check(this.$route.meta.power);
      if (state === false) {
        this.$router.push("/");
      }
    },
    async connect() {
      this.socket = new WebSocket(process.env.VUE_APP_WS_URL);
      this.stompClient = Stomp.over(this.socket);
      this.stompClient.connect(
        {},
        (frame) => {
          var t = frame;
          if (t.command == true) {
            return;
          }
          this.websocketCallback();
        },
        () => {
          this.reconnectWebsocket();
        }
      );
    },
    async reconnectWebsocket() {
      let connected = false;
      let connectionInterval = setInterval(() => {
        this.socket = new WebSocket(process.env.VUE_APP_WS_URL);
        this.stompClient = Stomp.over(this.socket);
        this.stompClient.connect(
          {},
          (frame) => {
            clearInterval(connectionInterval);
            connected = true;
            var t = frame;
            if (t.command == true) {
              return;
            }
            this.websocketCallback();
          },
          () => {
            if (connected) {
              this.reconnectWebsocket();
            }
          }
        );
      }, 5000);
    },
    async websocketCallback() {
      this.stompClient.subscribe(`/admin/socket/employee/shift/open/2`, () => {
        this.setAll();
      });
      this.stompClient.subscribe(`/admin/socket/employee/shift/close/2`, () => {
        this.setAll();
      });
    },
    async setAll() {
      await this.shiftList(this.input);
      this.parseEmployee(this.EMPLOYEE.cityEmployee);
    },
    parseEmployee(list) {
      this.couriers = [];
      this.pickers = [];
      list.forEach((element) => {
        let shift_result = this.fillShift(element);
        if (element.role.name === "Курьер") {
          this.couriers.push({
            employee: element,
            shift: shift_result.shift,
            result: shift_result.result,
          });
        } else {
          this.pickers.push({
            employee: element,
            shift: shift_result.shift,
            result: shift_result.result,
          });
        }
      });
    },
    fillShift(element) {
      let shift = [];
      shift.length = 31;
      let shifts = this.SCHEDULE.shiftList.filter(
        (val) => val.employee.id === element.id
      );
      let all_hours = 0;
      let all_minutes = 0;
      shifts.forEach((val) => {
        let date = moment(val.beginPlan).format("D");
        shift[date - 1] = val;
        if (shift[date - 1].endFact !== null) {
          shift[date - 1].minutes = moment(shift[date - 1].endFact).diff(
            shift[date - 1].beginFact,
            "minutes"
          );
          shift[date - 1].hours = Math.floor(shift[date - 1].minutes / 60);
          shift[date - 1].minutes = shift[date - 1].minutes % 60;
        } else {
          shift[date - 1].minutes = 0;
          shift[date - 1].hours = 0;
        }
        all_hours += shift[date - 1].hours || 0;
        all_minutes += shift[date - 1].minutes || 0;
      });
      if (all_minutes !== 0) all_hours += Math.floor(all_minutes / 60);
      if (all_minutes !== 0) all_minutes = all_minutes % 60;
      return { shift, result: { hours: all_hours, minutes: all_minutes } };
    },
  },
};
</script>
