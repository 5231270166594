<template>
  <div>
    <div class="scheduleMobileTable__wrapper">
      <div class="scheduleMobileTable__name-list">
        <v-col
          :style="{
            display: role == 0 || role == 1 ? '' : 'none',
          }"
          class="scheduleMobileTable__name-list_title"
          >Курьеры</v-col
        >
        <v-col
          v-for="item in couriers"
          :key="'curier_' + item.employee.id"
          :style="{
            display: role == 0 || role == 1 ? '' : 'none',
          }"
          class="scheduleMobileTable__name-list_item"
          @click="openModal(item.employee.id)"
        >
          <div class="scheduleMobileTable__name-list_name">
            {{ item.employee.username }}
          </div>
          <div>
            Всего отработано за месяц: {{ item.result.hours }}ч.
            {{ item.result.minutes }}м.
          </div>
          <div
            v-if="item.employee.onShift"
            class="scheduleMobileTable__item-status_true"
          >
            Сейчас Работает
          </div>
          <div
            v-if="!item.employee.onShift"
            class="scheduleMobileTable__item-status_false"
          >
            Сейчас Не работает
          </div>
        </v-col>
        <v-col
          :style="{
            display: role == 0 || role == 2 ? '' : 'none',
          }"
          class="scheduleMobileTable__name-list_title"
          >Сборщики</v-col
        >
        <v-col
          v-for="item in pickers"
          :key="'curier_' + item.employee.id"
          :style="{
            display: role == 0 || role == 2 ? '' : 'none',
          }"
          class="scheduleMobileTable__name-list_item"
          @click="openModal(item.employee.id)"
        >
          <div class="scheduleMobileTable__name-list_name">
            {{ item.employee.username }}
          </div>
          <div>
            Всего отработано за месяц: {{ item.result.hours }}ч.
            {{ item.result.minutes }}м.
          </div>
          <div
            v-if="item.employee.onShift"
            class="scheduleMobileTable__item-status_true"
          >
            Сейчас Работает
          </div>
          <div
            v-if="!item.employee.onShift"
            class="scheduleMobileTable__item-status_false"
          >
            Сейчас Не работает
          </div>
        </v-col>
      </div>
    </div>
    <v-overlay :value="calendarModal">
      <v-sheet
        v-if="chosenItem != null"
        width="80vw"
        style="display: flex; flex-direction: column"
        light
      >
        <v-container>
          <v-row>
            <v-col
              v-for="(day, index) in days"
              :key="'day_item' + index"
              cols="4"
              class="text-center schedualCalendarModal"
              :style="{
                background: day == 'Сб' || day == 'Вс' ? '#ffdada' : '',
                color: day == 'Сб' || day == 'Вс' ? 'black' : '',
                border:
                  $dark.get() === true
                    ? 'thin solid rgba(255, 255, 255, 0.3)'
                    : 'thin solid rgba(0, 0, 0, 0.12)',
              }"
              @click="
                dialog = true;
                shift = chosenItem.shift[index] || chosenItem;
                getDay(index);
              "
            >
              <div>{{ day + " " + (index + 1) }}</div>
              <div v-if="chosenItem.shift[index]">
                {{ chosenItem.shift[index].hours || 0 }}ч.
                {{ chosenItem.shift[index].minutes || 0 }}м.
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-sheet>
      <div style="display: flex; justify-content: center">
        <v-btn @click="openModal(null)">ЗАКРЫТЬ КАЛЕНДАРЬ</v-btn>
      </div>
    </v-overlay>
    <schedule-dialog
      :dialog="dialog"
      :shift="shift"
      :input="input"
      :shift-date="shiftDate"
      @close-modal="dialog = false"
      @rerender="$emit('rerender')"
    ></schedule-dialog>
  </div>
</template>
<script>
import moment from "moment";
import ScheduleDialog from "./ScheduleDialog";
export default {
  components: { ScheduleDialog },
  props: {
    date: {
      type: String,
      default: null,
    },
    pickers: {
      type: Array,
      default: null,
    },
    couriers: {
      type: Array,
      default: null,
    },
    role: {
      type: Number,
      default: 0,
    },
    input: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      dialog: false,
      shift: {},
      shiftDate: "",
      calendarModal: false,
      chosenItemId: null,
      daysOfWeek: ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"],
    };
  },
  computed: {
    days() {
      moment.locale("ru");
      let days = moment(this.date, "YYYY-MM").daysInMonth();
      let week_title = ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"];
      let days_week = [];
      for (let i = 1; i <= days; i++) {
        days_week[i - 1] =
          week_title[moment(this.date + "-" + i, "YYYY-MM-DD").weekday()];
      }
      return days_week;
    },
    chosenItem() {
      let data = null;
      if (this.pickers && this.couriers) {
        this.pickers.forEach((el) => {
          if (el.employee.id === this.chosenItemId) data = el;
        });
        this.couriers.forEach((el) => {
          if (el.employee.id === this.chosenItemId) data = el;
        });
      }

      return data;
    },
  },
  methods: {
    getDay(val) {
      val++;
      if (val < 10) {
        val = "0" + val;
      }
      this.shiftDate = this.date + "-" + val;
    },
    openModal(val) {
      this.calendarModal = !this.calendarModal;
      this.chosenItemId = val;
    },
  },
};
</script>
<style lang="scss">
.schedualCalendarModal {
  cursor: pointer;
  height: 60px;
  &:hover {
    background-color: #ccc !important;
  }
}
.scheduleMobileTable__wrapper {
  height: 60vh;
  overflow: scroll;
}
.scheduleMobileTable__name-list_title {
  font-weight: bold;
  text-align: center;
  border-bottom: solid 2px;
  margin-bottom: 5px;
  font-size: 1.4rem;
}
.scheduleMobileTable__name-list_item {
  padding: 20px;
  border: solid 1px;
  border-radius: 8px;
  box-sizing: border-box;
  & + .scheduleMobileTable__name-list_item {
    margin-top: 10px;
  }
}
.scheduleMobileTable__name-list_name {
  width: 100%;
  font-weight: bold;
  font-size: 1.1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.scheduleMobileTable__item-status_false {
  padding: 5px;
  background-color: lightcoral;
  text-align: center;
  font-weight: bold;
}
.scheduleMobileTable__item-status_true {
  padding: 5px;
  background-color: lightgreen;
  text-align: center;
  font-weight: bold;
}
</style>
