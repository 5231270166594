<template>
  <v-dialog
    v-model="modal"
    width="80%"
    @click:outside="$emit('update:modal', false)"
  >
    <v-card class="modal" :dark="$dark.get()">
      <v-card-title> Распределение графика </v-card-title>
      <v-card-text v-if="employee" class="modal__text">
        <v-form ref="form">
          <v-select
            v-model="planType"
            label="График работы"
            item-text="name"
            :items="
              employee.role.id === 3
                ? SCHEDULE.couriersPlan
                : SCHEDULE.pickersPlan
            "
            return-object
            :rules="[$validate.required]"
          ></v-select>
          <div class="space"></div>
          <v-menu
            ref="menu"
            v-model="menu1"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            :dark="$dark.get()"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                label="Начало графика"
                :value="date.dayStart"
                readonly
                outlined
                v-bind="attrs"
                hide-details
                dense
                :rules="[$validate.required]"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date.dayStart"
              no-title
              :max="date.dayEnd"
              @change="menu1 = !menu1"
            >
            </v-date-picker>
          </v-menu>
          <div class="space"></div>
          <v-menu
            ref="menu"
            v-model="menu2"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            :dark="$dark.get()"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                label="Конец графика"
                :value="date.dayEnd"
                readonly
                outlined
                v-bind="attrs"
                hide-details
                dense
                :rules="[$validate.required]"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date.dayEnd"
              no-title
              :min="date.dayStart"
              @change="menu2 = !menu2"
            >
            </v-date-picker>
          </v-menu>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn color="warning" @click="$router.push(`/employee/${employee.id}`)"
          >Расписание
          <v-icon>mdi-arrow-right-circle</v-icon>
        </v-btn>
        <v-btn @click="$emit('update:modal', false)">Закрыть</v-btn>
        <v-btn color="primary" @click="assignTimeShedule()">Создать</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import showMessage from "@/Functions/message";
import api from "@/api";

export default {
  name: "PlanModal",
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
    employee: {
      type: Object,
      default: null,
    },
    dateProp: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      input: {},
      planType: null,
      date: {},
      menu1: false,
      menu2: false,
    };
  },
  computed: {
    ...mapGetters({
      SCHEDULE: "Schedule/STATE",
    }),
  },
  watch: {
    modal(val) {
      if (!val) {
        this.$refs.form.resetValidation();
      } else {
        this.date.dayStart = this.dateProp[0];
        this.date.dayEnd = this.dateProp[1];
      }
    },
  },
  methods: {
    async assignTimeShedule() {
      if (!this.$refs.form.validate()) {
        showMessage("Заполните все поля");
        return;
      }
      const payload = {
        beginPlan: this.planType.timeBegin,
        endPlan: this.planType.timeEnd,
        breakAmountPlan: this.planType.breakAmountPlan,
        breakTimePlan: this.planType.breakTimePlan,
        employeeId: this.employee.id,
        departmentId: this.planType.department.id,
        dayStart: this.date.dayStart,
        dayEnd: this.date.dayEnd,
      };
      const response = await api.Timetable.assignTimeShedule(payload);
      if (response.type === "error") {
        showMessage(response.data.message);
      } else {
        showMessage("График создан", true);
        this.$emit("rerender");
        this.$emit("update:modal", false);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.space {
  height: 10px;
}
</style>
