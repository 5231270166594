<template>
  <v-row id="printMe" class="scheduleFilter__wrapper">
    <v-col sm="3" xs="12">
      <v-menu
        ref="menu"
        v-model="menu"
        transition="scale-transition"
        offset-y
        :dark="$dark.get()"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :value="dateFormat"
            label="Месяц"
            readonly
            outlined
            v-bind="attrs"
            hide-details
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker v-model="input.date" type="month" no-title>
        </v-date-picker>
      </v-menu>
    </v-col>
    <v-col sm="3" xs="12">
      <v-select
        v-model="filter.role"
        outlined
        item-value="id"
        item-text="name"
        :items="roles"
        hide-details
      >
      </v-select>
    </v-col>
    <v-col sm="3" xs="12">
      <v-select
        v-model="input.departmentId"
        outlined
        item-text="name"
        item-value="id"
        :items="departments"
        hide-details
        :readonly="departments.length === 1"
      ></v-select>
    </v-col>
    <v-col
      v-if="!mobileMode"
      sm="3"
      xs="12"
      class="d-flex align-center justify-end"
    >
      <v-tooltip left color="#e9e9e9" content-class="custom-tooltip_shop">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-information-slab-circle-outline</v-icon>
          </v-btn>
        </template>
        <span class="tooltip">
          <span class="text-status_blue-text">Запланированная</span>
          <span>Подтвержденная</span>
          <span class="text-status_red-text">Отменённые</span>
          <span class="text-status_red-background">Пропущенная </span>
          <span class="text-status_yellow">С опозданием</span>
          <span class="text-status_green-text">Без опоздания</span>
          <span class="text-status_yellow-text">Сокращенный день</span>
          <span class="text-status_orange-text">Перерыв</span>
        </span>
      </v-tooltip>
      <v-btn color="primary" fab small @click="$emit('print')">
        <v-icon>mdi-printer</v-icon>
      </v-btn>
      <v-btn class="ml-2" color="success" fab small @click="$emit('excel')">
        <v-icon>mdi-file-excel</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
import moment from "moment";
export default {
  props: {
    roles: {
      type: Array,
      required: true,
    },
    filter: {
      type: Object,
      required: true,
    },
    input: {
      type: Object,
      required: true,
    },
    departments: {
      type: Array,
      required: true,
    },
    mobileMode: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      menu: false,
    };
  },
  computed: {
    dateFormat() {
      return this.input.date
        ? moment(this.input.date).format("За MMMM YYYY")
        : "";
    },
  },
};
</script>
<style lang="scss">
.prob {
  position: absolute;
}
.custom-tooltip_shop {
  font-size: 18px;
  padding: 16px;
  width: 200px;
  height: 300px;
}
.scheduleFilter__wrapper {
  display: flex;
  @media screen and (max-width: 600px) {
    display: block;
    & * {
      padding: 0;
    }
    padding-bottom: 20px;
  }
}
.tooltip {
  display: flex;
  flex-direction: column;
  color: black;
  gap: 10px;
}
</style>
