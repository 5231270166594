<template>
  <v-dialog
    v-model="dialog"
    width="60%"
    persistent
    :dark="$dark.get()"
    @click:outside="$emit('update:dialog', false)"
  >
    <v-form>
      <v-card class="pb-4 pl-2 pr-2">
        <v-card-title> Доступные слоты </v-card-title>
        <v-card-text v-if="data">
          <p>{{ data.date }}</p>
          <div class="slot">
            <p class="slot_header">Слоты курьеров :</p>
            <div
              v-for="slot in data.courierSlot"
              :key="slot.id"
              class="slot_box"
            >
              <p>
                Для смены: {{ slot.startTime }}:{{ slot.endTime }} -
                {{ `${slot.confirmedCount}/${slot.count}` }}
              </p>
              <v-btn
                v-if="showEdit === slot.slotId"
                icon
                color="primary"
                @click="showEdit = 0"
              >
                <v-icon>mdi-close</v-icon></v-btn
              >
              <v-btn v-else icon color="primary" @click="showEditCount(slot)">
                <v-icon>mdi-pencil</v-icon></v-btn
              >
              <div v-if="showEdit === slot.slotId" class="slot_edit">
                <v-text-field
                  v-model="editedSlotCount"
                  type="number"
                  label="Количество слотов"
                  class="slot_edit__input"
                />
                <v-btn small color="primary" @click="editSlot(slot)"
                  >Изменить</v-btn
                >
              </div>
            </div>
            <p v-if="!data.courierSlot.length">Нет слотов</p>
          </div>
          <div class="slot">
            <p class="slot_header">Слоты сборщиков :</p>

            <div
              v-for="slot in data.pickerSlot"
              :key="slot.slotId"
              class="slot_box"
            >
              <p>
                Для смены: {{ slot.startTime }}:{{ slot.endTime }} -
                {{ `${slot.confirmedCount}/${slot.count}` }}
              </p>
              <v-btn
                v-if="showEdit === slot.slotId"
                icon
                color="primary"
                @click="showEdit = 0"
              >
                <v-icon>mdi-close</v-icon></v-btn
              >
              <v-btn v-else icon color="primary" @click="showEditCount(slot)">
                <v-icon>mdi-pencil</v-icon></v-btn
              >
              <div v-if="showEdit === slot.slotId" class="slot_edit">
                <v-text-field
                  v-model="editedSlotCount"
                  type="number"
                  label="Количество слотов"
                  class="slot_edit__input"
                />
                <v-btn small color="primary" @click="editSlot(slot)"
                  >Изменить</v-btn
                >
              </div>
            </div>
            <p v-if="!data.pickerSlot.length">Нет слотов</p>
          </div>
        </v-card-text>
        <v-card-actions class="actions">
          <div>
            <v-btn @click="$emit('update:dialog', false)">Закрыть</v-btn>
            <v-btn color="primary" @click="create = true">Создать слоты</v-btn>
          </div>
          <div v-if="create">
            <v-form ref="slotForm">
              <v-select
                v-model="input.roleId"
                :items="types"
                item-text="name"
                item-value="id"
                label="Слоты для ..."
                :rules="[$validate.required]"
              />
              <v-select
                v-model="planType"
                label="График работы"
                item-text="name"
                :items="
                  input.roleId === 3
                    ? SCHEDULE.couriersPlan
                    : SCHEDULE.pickersPlan
                "
                return-object
                :rules="[$validate.required]"
              ></v-select>
              <v-text-field
                v-model="input.count"
                type="number"
                label="Количество слотов"
                :rules="[$validate.required]"
              />
            </v-form>
            <div>
              <v-btn small @click="create = false">Отмена</v-btn>
              <v-btn small color="primary" @click="addSlot()"
                >Подтвердить</v-btn
              >
            </div>
          </div>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import api from "@/api";
import showMessage from "@/Functions/message";
export default {
  name: "SlotModal",
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: null,
    },
    dep: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      create: false,
      types: [
        { id: 3, name: "Курьеры" },
        { id: 2, name: "Сборщики" },
      ],
      input: {},
      showEdit: 0,
      editedSlotCount: 0,
      planType: null,
    };
  },
  computed: {
    ...mapGetters({
      SCHEDULE: "Schedule/STATE",
    }),
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.create = false;
        this.showEdit = 0;
      }
    },
  },
  methods: {
    ...mapActions({
      scheduleList: "Schedule/SCHEDULE_LIST",
    }),
    async addSlot() {
      if (!this.$refs.slotForm.validate()) {
        showMessage("Заполните все поля");
        return;
      }
      console.log(this.planType);
      const payload = {
        departmentId: this.dep,
        roleId: this.input.roleId,
        count: this.input.count,
        day: this.data.date,
        startTime: this.planType.timeBegin,
        endTime: this.planType.timeEnd,
        breakTimePlan: this.planType.breakTimePlan,
        breakAmountPlan: this.planType.breakAmountPlan,
      };
      const response = await api.Schedule.addSlot(payload);
      if (response.type === "error") {
        showMessage(response.data.message);
      } else {
        showMessage("Слот создан", true);
        this.$emit("rerender");
        this.$emit("update:dialog", false);
      }
    },
    async editSlot(slot) {
      const payload = {
        id: slot.slotId,
        count: this.editedSlotCount,
        startTime: slot.startTime,
        endTime: slot.endTime,
      };
      const response = await api.Schedule.editSlot(payload);
      if (response.type === "error") {
        showMessage(response.data.message);
      } else {
        showMessage("Слот изменен", true);
        slot.count = this.editedSlotCount;
        this.showEdit = 0;
        this.$emit("rerender");
      }
    },
    showEditCount(slot) {
      this.showEdit = slot.slotId;
      this.editedSlotCount = slot.count;
    },
  },
};
</script>
<style lang="scss" scoped>
.slot {
  &_header {
    color: black;
    font-weight: bold;
  }
  &_edit {
    display: flex;
    align-items: center;
    gap: 10px;
    &__input {
      max-width: 100px !important;
    }
  }
  &_box {
    display: flex;
    align-items: center;
    gap: 10px;
    p {
      margin: 0;
    }
  }
}
.actions {
  flex-direction: column;
  justify-content: stretch;
  div {
    width: 100%;
  }
}
</style>
